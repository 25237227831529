import * as React from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link';
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from '@material-ui/core/Button';

type NextComposedProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  NextLinkProps;

const NextComposed = React.forwardRef<HTMLAnchorElement, NextComposedProps>(
  (props, ref) => {
    const {
      as,
      href,
      replace,
      scroll,
      passHref,
      shallow,
      prefetch,
      ...other
    } = props;

    return (
      <NextLink
        href={href}
        prefetch={prefetch}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading, jsx-a11y/anchor-has-content */}
        <a ref={ref} {...other} />
      </NextLink>
    );
  },
);

interface LinkPropsBase {
  activeClassName?: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
  naked?: boolean;
}

type LinkProps = LinkPropsBase & NextComposedProps & Omit<MuiLinkProps, 'ref'>;
type ButtonProps = LinkPropsBase &
  NextComposedProps &
  Omit<MuiButtonProps, 'ref'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Link: React.FC<LinkProps> = props => {
  const {
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    ...other
  } = props;
  const router = useRouter();

  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === other.href && activeClassName,
  });

  if (naked) {
    return <NextComposed className={className} ref={innerRef} {...other} />;
  }

  return (
    <MuiLink
      component={NextComposed}
      className={className}
      ref={innerRef}
      {...other}
    />
  );
};

const LinkWithRef = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link {...props} innerRef={ref} />,
);

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Button: React.FC<ButtonProps> = props => {
  const {
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    ...other
  } = props;
  const router = useRouter();

  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === other.href && activeClassName,
  });

  if (naked) {
    return <NextComposed className={className} ref={innerRef} {...other} />;
  }

  return (
    <MuiButton
      component={NextComposed}
      className={className}
      ref={innerRef}
      {...other}
    />
  );
};

const ButtonWithRef = React.forwardRef<HTMLAnchorElement, ButtonProps>(
  (props, ref) => <Button {...props} innerRef={ref} />,
);

export default LinkWithRef;

export { LinkWithRef as Link, ButtonWithRef as Button };
